//(fos) is sof reversed
export const LOCAL_SOFS_KEY = "encrypted-fos";

//(ac) is ca (service) reversed
export const LOCAL_SERVICES_KEY = "encrypted-ac";

export class EncryptedLocalStorage {
  private static encrypt(value: string) {
    return btoa(encodeURIComponent( value));
  }

  private static decrypt(value: string) {
    const decrypted = decodeURIComponent(atob(value));
    try {
      return JSON.parse(decrypted);
    } catch (e) {
      return decrypted;
    }
  }

  static hasData(key: string) {
    return !!localStorage.getItem(key);
  }

  static setItem(key: string, value: string) {
    // Encrypt the value
    const encryptedValue = EncryptedLocalStorage.encrypt(value);
    localStorage.setItem(key, encryptedValue);
  }

  static getItem<T>(key: string): T | null {
    // Decrypt the value
    const encryptedValue = localStorage.getItem(key);

    if (!encryptedValue) {
      return null;
    }

    const decryptedValue = EncryptedLocalStorage.decrypt(encryptedValue);
    return decryptedValue;
  }

  static clear(all: boolean = false) {
    if (all) {
      localStorage.clear();
      return;
    }

    Object.keys(localStorage).forEach((key) => {
      if (key in [LOCAL_SERVICES_KEY, LOCAL_SOFS_KEY]) {
        localStorage.removeItem(key);
      }
    });
  }
}
